// Dev note: Keep this file as small as posible. With UI elements and ELMO desing system
// you rarely need to have your own extra style. So each time you want to add some style
// here ask yourself and designers if you really have to
.app-loading-element {
  position: fixed;
  top: 45%;
  left: 50%;
  font-size: 12px;
  text-align: center;
}
.margin-bottom-20 {
  margin-bottom: 20px;
}

// this is to disable the eds3 button focus, otherwise there is always a highlighted effects on tab click.
button:focus {
  outline: none !important;
}
